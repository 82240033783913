<template>
    <div style="width: 100%; height: 100%;overflow: hidden;">
        <iframe ref="iframeRef" style="width: 100%; height: 100%" frameborder="0"></iframe>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
// import { useUserStore } from "@/store/modules/user";
import { useRoute, useRouter } from "vue-router";
// const userStore = useUserStore();

const router = useRouter()
const route = useRoute()
const iframeRef = ref()

watch(route, () => {
    initSource()
},{
    deep: true
});

const initSource = ()=>{
    iframeRef.value.src = location.origin + location.hash.split('/centerMonitor/customize')[1] + '/#/'
    // iframeRef.value.onload = function () {
    //     iframeRef.value.contentWindow.postMessage(JSON.stringify({
    //         origin: location.href,
    //         info: {
    //             token: userStore.token,
    //             tenantId: userStore.userInfo.tenantId
    //         }
    //     }), '*')
    // }
}

onMounted(() => {
    initSource()
    window.addEventListener("message",function(event){
        if(event.data&&event.data.msg){
            sessionStorage.setItem('areaName', event.data.msg)
            sessionStorage.setItem("customTabName", '项目看板');
            router.push('/centerMonitor/customize/custom/lh/#/projectBoard?')
            
        }
    },false)
})
</script>
<style lang='less' scoped></style>